import React from "react";
import SectionTitle from "../Common/SectionTitle";
import Filter from "./Fliter";

const Portfolio = () => {
  return (
    <>
      <section
        className="relative section container mx-auto px-0 md:px-16 flex flex-col gap-5"
        id="portfolio"
      >
        <SectionTitle
          title="Our Portfolio"
          paragraph="Explore some of our latest work and case studies for clients. We worked with them to deliver a solution that met their needs and helped them achieve their goals."
          center
        />
        <Filter />
      </section>
    </>
  );
};

export default Portfolio;
