import React from "react";
import { Header, Hero, Features, ScrollToTop, About, More, Contact, Footer, Testimonial, Portfolio } from "../components/index";

const Home = () => {
  window.scrollTo({
    top: "0",
    behavior: "smooth",
  });
  return (
    <>
      <ScrollToTop />
      <div className="transition-all duration-300 dark:bg-gray-900 min-h-screen">
        <Header />
        <Hero />
        <About />
        <More />
        <Features />
        <Portfolio />
        <Testimonial />
        <Contact />
        <Footer />
      </div>
    </>
  );
};

export default Home;
