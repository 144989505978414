import React, { useEffect, useState } from "react";
import { images, btns } from "./Data";
import Modal from "./Modal";

const Filter = () => {
  const [filterImages, setFilterImages] = useState([]);
  const [active, setActive] = useState(false);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedImage, setSelectedImage] = useState(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [imagesToDisplay, setImagesToDisplay] = useState(9);

  useEffect(() => {
    // Determine how many images are left to display
    const remainingImages = images.length - imagesToDisplay;
    const imagesToLoad = Math.min(3, remainingImages);
    const newImagesToDisplay = imagesToDisplay + imagesToLoad;
    setFilterImages(images.slice(0, newImagesToDisplay)); // Display the first images
  }, [imagesToDisplay]);

  const handleClick = (e) => {
    const btnValue = e.target.value;
    setActive(btnValue);
    const newFilterImages = images.filter((item) => item.category === btnValue);
    if (btnValue === "all") {
      setFilterImages(images.slice(0, imagesToDisplay)); // Display the first images
    } else {
      setFilterImages(newFilterImages.slice(0, imagesToDisplay)); // Display the first images
    }
  };

  const openModal = (image, description, name) => {
    const index = filterImages.findIndex((item) => item.img === image);
    setSelectedImage({ image, description, name });
    setCurrentIndex(index);
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const goToNextImage = () => {
    if (currentIndex < filterImages.length - 1) {
      openModal(
        filterImages[currentIndex + 1].img,
        filterImages[currentIndex + 1].description,
        filterImages[currentIndex + 1].name
      );
    }
  };

  const goToPreviousImage = () => {
    if (currentIndex > 0) {
      openModal(
        filterImages[currentIndex - 1].img,
        filterImages[currentIndex - 1].description,
        filterImages[currentIndex - 1].name
      );
    }
  };

  return (
    <div className="w-full flex justify-center container items-center flex-col">
      {/* button */}
      <div className="w-full flex justify-center items-center">
        <div className="flex flex-wrap mb-6 gap-3 w-full justify-center items-center">
          {btns.map((item, id) => {
            const { name, value } = item;
            return (
              <button
                onClick={handleClick}
                key={id}
                value={value}
                className={`${
                  active === value ? "bg-primary text-white" : ""
                } py-3 px-5 inline-block rounded-xl border-[1px] transition-all duration-300 hover:bg-primary border-solid border-primary text-xs md:text-sm font-semibold hover:text-white text-primary`}
              >
                {name}
              </button>
            );
          })}
        </div>
      </div>

      {/* images */}
      <div className="grid md:grid-cols-3 gap-5">
        {filterImages.map((item, id) => {
          const { img, description, name } = item;
          return (
            <div
              className="overflow-hidden border-4 wow fadeIn border-solid border-green rounded-md group"
              data-wow-duration="2s"
              data-wow-delay="5s"
              key={id}
            >
              <img
                className="w-full h-full cursor-pointer object-cover group-hover:scale-110 transition-all duration-500 rounded-md"
                src={img}
                alt="demo-img"
                onClick={() => openModal(img, description, name)}
              />
              <h1>{name}</h1>
              <p>{description}</p>
            </div>
          );
        })}
      </div>

      {imagesToDisplay <= images.length && (
        <div className="w-full flex justify-center mt-4">
          <button
            onClick={() => setImagesToDisplay(imagesToDisplay + 3)} // Load 3 more images
            className="py-3 px-5 inline-block rounded-xl border-[1px] transition-all duration-300 hover:bg-primary border-solid border-primary text-xs md:text-sm font-semibold hover:text-white text-primary mt-4"
          >
            Load More
          </button>
        </div>
      )}

      {modalOpen && (
        <Modal
          isOpen={modalOpen}
          image={selectedImage.image}
          description={selectedImage.description}
          name={selectedImage.name}
          onClose={closeModal}
          onPrev={goToPreviousImage}
          onNext={goToNextImage}
        />
      )}
    </div>
  );
};

export default Filter;
