import React from "react";
import Breadcrumb from "../components/Common/Breadcrumb";
import { ScrollToTop, About, More, Footer, Header } from "../components/index";

const AboutUs = () => {
  window.scrollTo({
    top: "0",
    behavior: "smooth",
  });
  return (
    <>
      <ScrollToTop />
      <div className="transition-all duration-300 dark:bg-gray-900 min-h-screen">
        <Header />
        <Breadcrumb pageName="About Us" description="Here you will find more information about Next Oasis Limited in detail." />
        <About />
        <More />
        <Footer />
      </div>
    </>
  );
};

export default AboutUs;
