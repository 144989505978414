import image1 from "../../assets/images/portfolio/thumb-1.jpg";
import image2 from "../../assets/images/portfolio/thumb-3.jpg";
import image3 from "../../assets/images/portfolio/ui1.jpeg";
import image4 from "../../assets/images/portfolio/ui2.jpeg";
import image5 from "../../assets/images/portfolio/ui3.jpeg";
import image6 from "../../assets/images/portfolio/ui4.jpeg";
import image7 from "../../assets/images/portfolio/ui5.jpeg";
import image8 from "../../assets/images/portfolio/graphics.jpg";
import image10 from "../../assets/images/portfolio/graphics (3).jpg";
import image11 from "../../assets/images/portfolio/graphics (2).jpg";
import image12 from "../../assets/images/portfolio/graphics (1).png";
import image13 from "../../assets/images/portfolio/graphics (4).jpg";
import image14 from "../../assets/images/portfolio/graphics (5).jpg";
import image15 from "../../assets/images/portfolio/graphics (6).jpg";
import image9 from "../../assets/images/portfolio/ui.jpeg";

export const images = [
  {
    id: 1,
    img: image1,
    category: "Web development",
  },
  {
    id: 2,
    img: image2,
    category: "Web development",
  },
  {
    id: 9,
    img: image9,
    category: "UI/UX",
  },
  {
    id: 10,
    img: image10,
    category: "Graphics",
  },
  {
    id: 11,
    img: image11,
    category: "Graphics",
  },
  {
    id: 12,
    img: image12,
    category: "Graphics",
  },
  {
    id: 3,
    img: image3,
    category: "UI/UX",
  },
  {
    id: 4,
    img: image4,
    category: "App development",
  },
  {
    id: 5,
    img: image5,
    category: "UI/UX",
  },
  {
    id: 6,
    img: image6,
    category: "App development",
  },
  {
    id: 7,
    img: image7,
    category: "App development",
  },
  {
    id: 13,
    img: image13,
    category: "Graphics",
  },
  {
    id: 14,
    img: image14,
    category: "Graphics",
  },
  {
    id: 15,
    img: image15,
    category: "Graphics",
  },
  {
    id: 8,
    img: image8,
    category: "Graphics",
  },
];

export const btns = [
  { id: 1, name: "All", value: "all" },
  { id: 2, name: "Web development", value: "Web development" },
  { id: 3, name: "App development", value: "App development" },
  { id: 4, name: "UI/UX", value: "UI/UX" },
  { id: 5, name: "Graphics", value: "Graphics" },
];
