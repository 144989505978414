import React, { useState } from "react";
import NewsLatterBox from "./NewsLatterBox";
import SuccessModal from "./SuccessModal";
import ErrorModal from "./ErrorModal";
import Loading from "./Loading";
// import validationSuite from './validation';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [isSuccessModalOpen, setIsSuccessModalOpen] = useState(false);
  const [isLoading, setLoading] = useState(false);
  const [isErrorModalOpen, setIsErrorModalOpen] = useState(false); // State for the error modal

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();

    try {
      const response = await fetch("https://nextoasis-api.vercel.app/send-email", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        setLoading(false);
        setIsSuccessModalOpen(true);
        console.log("loading true");
        setFormData({ name: "", email: "", message: "" });
      } else {
        setIsErrorModalOpen(true); // Show error modal in case of an error
      }
    } catch (error) {
      console.error(error);
      setLoading(false);
      setIsErrorModalOpen(true); // Show error modal on network error
    } finally {
      setLoading(false); // Set loading back to false when the operation is completed.
    }
  };

  const closeSuccessModal = () => {
    setIsSuccessModalOpen(false);
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };

  const closeErrorModal = () => {
    setIsErrorModalOpen(false);
  };
  return (
    <>
      <SuccessModal
        isOpen={isSuccessModalOpen}
        closeModal={closeSuccessModal}
      />
      <Loading isLoading={isLoading} />
      <ErrorModal isOpen={isErrorModalOpen} closeModal={closeErrorModal} />
      {isLoading ? (
        <Loading isLoading={isLoading} /> // You can use the Loading component here if you have one.
      ) : (
        <section
          id="contact"
          className="overflow-hidden py-16 md:py-20 lg:py-28"
        >
          <div className="container">
            <div className="-mx-4 flex flex-wrap">
              <div className="w-full px-4 lg:w-7/12 xl:w-8/12">
                <div
                  className="wow fadeInUp shadow-three dark:bg-gray-dark mb-12 rounded-sm bg-white px-8 py-11 sm:p-[55px] lg:mb-5 lg:px-8 xl:p-[55px]"
                  data-wow-delay=".15s"
                >
                  <h2 className="mb-3 text-2xl font-bold text-black dark:text-white sm:text-3xl lg:text-2xl xl:text-3xl">
                    Contact Us<span className="text-primary">.</span>
                  </h2>
                  <p className="mb-12 text-base font-medium text-body-color">
                    Our support team will get back to you ASAP via email.
                  </p>
                  <form onSubmit={handleSubmit}>
                    <div className="-mx-4 flex flex-wrap">
                      <div className="w-full px-4 md:w-1/2">
                        <div className="mb-8">
                          <label
                            htmlFor="name"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            Your Name
                          </label>
                          <input
                            type="text"
                            name="name"
                            required
                            value={formData.name}
                            onChange={handleChange}
                            placeholder="Enter your name"
                            className="border-stroke dark:text-body-color-dark dark:shadow-two w-full rounded-sm border bg-[#f8f8f8] px-6 py-3 text-base text-body-color outline-none focus:border-primary dark:border-transparent dark:bg-[#2C303B] dark:focus:border-primary dark:focus:shadow-none"
                          />
                        </div>
                      </div>
                      <div className="w-full px-4 md:w-1/2">
                        <div className="mb-8">
                          <label
                            htmlFor="email"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            Your Email
                          </label>
                          <input
                            type="email"
                            name="email"
                            required
                            value={formData.email}
                            onChange={handleChange}
                            placeholder="Enter your email"
                            className="border-stroke dark:text-body-color-dark dark:shadow-two w-full rounded-sm border bg-[#f8f8f8] px-6 py-3 text-base text-body-color outline-none focus:border-primary dark:border-transparent dark:bg-[#2C303B] dark:focus:border-primary dark:focus:shadow-none"
                          />
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <div className="mb-8">
                          <label
                            htmlFor="message"
                            className="mb-3 block text-sm font-medium text-dark dark:text-white"
                          >
                            Your Message
                          </label>
                          <textarea
                            name="message"
                            rows={5}
                            required
                            value={formData.message}
                            onChange={handleChange}
                            placeholder="Enter your Message"
                            className="border-stroke dark:text-body-color-dark dark:shadow-two w-full resize-none rounded-sm border bg-[#f8f8f8] px-6 py-3 text-base text-body-color outline-none focus:border-primary dark:border-transparent dark:bg-[#2C303B] dark:focus:border-primary dark:focus:shadow-none"
                          ></textarea>
                        </div>
                      </div>
                      <div className="w-full px-4">
                        <button
                          disabled={isLoading}
                          className="shadow-submit dark:shadow-submit-dark rounded-sm bg-primary px-9 py-4 text-base font-medium text-white duration-300 hover:bg-primary/90"
                        >
                          {isLoading ? "Sending..." : "Send Message"}
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="w-full px-4 lg:w-5/12 xl:w-4/12">
                <NewsLatterBox />
              </div>
            </div>
          </div>
        </section>
      )}
    </>
  );
};

export default Contact;
