import React from "react";
import Breadcrumb from "../components/Common/Breadcrumb";
import { ScrollToTop, Footer, Header, Portfolio } from "../components/index";

const OurPortfolio = () => {
  window.scrollTo({
    top: "0",
    behavior: "smooth",
  });
  return (
    <>
      <ScrollToTop />
      <div className="transition-all duration-300 dark:bg-gray-900 min-h-screen">
        <Header />
        <Breadcrumb
          pageName="Our Portfolios"
          description="Here you will find more information about Next Oasis Limited in detail."
        />
        <Portfolio />
        <Footer />
      </div>
    </>
  );
};

export default OurPortfolio;
