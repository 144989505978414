import { FaStar, FaStarHalf } from "react-icons/fa";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import ButtonGroup from "../Common/ButtonGroup";
import SectionTitle from "../Common/SectionTitle";

const Card = ({ star, title, imgSrc, testimony, name, occupation }) => {
  return (
    <div className="mx-2 font-dm  flex flex-col gap-2 select-none text-black dark:text-white border border-[#4e4e4e31] p-5 rounded-lg hover:shadow-xl transition-all">
      <div className="text-primary flex gap-1">
        <FaStar />
        <FaStar />
        <FaStar />
        <FaStar />
        {star}
      </div>
      {/* <h2 className="text-lg font-semibold  mb-2">{title}</h2> */}
      <p className="leading-loose text-xs">{testimony}</p>
      <div className="flex items-center gap-2 mt-2">
        <img
          className=" rounded-lg w-14 aspect-square object-cover object-top"
          src={imgSrc}
          width={40}
          height={40}
          alt="testimonials"
        />
        <div className="flex flex-col gap-px">
          <h3 className="font-semibold text-sm">{name}</h3>
          <p className="text-primary font-[500] text-xs">{occupation}</p>
        </div>
      </div>
    </div>
  );
};

const Testimonials = () => {
  return (
    <section
      className="relative section container mx-auto px-0 md:px-16 flex flex-col"
      id="testimonial"
    >
         <SectionTitle
            title="Our Testimonials"
            paragraph="Encouraging innovation and creativity can lead to the development of new products or services that exceed client expectations and drive organizational growth."
            center
          />
      <Carousel {...carouselParams}>
        <div>
          <Card
            star={<FaStarHalf />}
            testimony="I recently used Next Oasis for our Corporate Design project and they exceeded my expectations. Their work is outstanding and turnaround amazing. I would not hesitate to recommend them to anyone."
            imgSrc="/images/testimonials/auth-02.png"
            name="Shula Duku"
            // occupation="Founder of ART SPACE"
          />
        </div>
        <div>
          <Card
            star={<FaStar />}
            title="Design Quality & performance"
            imgSrc="/images/testimonials/auth-03.png"
            name="James Achnif"
            // occupation="BANKER"
            testimony="Thank you for your efficient and professional service. And more importantly the result we got at the end of the Project."
          />
        </div>
        <div>
          <Card
            star={<FaStar />}
            title="Layout and organized layers"
            imgSrc="/images/testimonials/auth-01.png"
            name="Philip Dah"
            // occupation="Software Developer"
            testimony="I've been using Next Oasis for the past years, and I can't express how much it has transformed the way I run my business. The user-friendly interface and robust features have made managing my projects a breeze."
          />
        </div>
        {/* <div>
          <Card
            star={<FaStarHalf />}
            title="Layout and organized layers"
            imgSrc="/testimonials/3.png"
          />
        </div> */}
      </Carousel>
    </section>
  );
};

export default Testimonials;

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 4,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 3,
  },
  tablet: {
    breakpoint: { max: 1024, min: 464 },
    items: 2,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 1,
  },
};

const carouselParams = {
  additionalTransfrom: 0,
  arrows: false,
  autoPLaySpeed: 100,
  centerMode: false,
  className: "",
  containerClass: "carousel-container",
  customButtonGroup: <ButtonGroup />,
  dotListClass: "",
  draggable: true,
  focusOnSelect: false,
  infinite: true,
  itemClass: "",
  keyBoardControl: true,
  minimumTouchDrag: 80,
  renderButtonGroupOutside: true,
  renderDotsOutside: false,
  responsive: responsive,
  showDots: false,
  sliderClass: "",
  slidesToSlide: 1,
};
