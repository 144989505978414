import React from "react";
import Breadcrumb from "../components/Common/Breadcrumb";
import { ScrollToTop, Footer, Header, Features } from "../components/index";

const Service = () => {
  window.scrollTo({
    top: "0",
    behavior: "smooth",
  });
  return (
    <>
      <ScrollToTop />
      <div className="transition-all duration-300 dark:bg-gray-900 min-h-screen">
        <Header />
        <Breadcrumb pageName="Our Services" description="Here you will find more information about Next Oasis Limited in detail." />
        <Features />
        <Footer />
      </div>
    </>
  );
};

export default Service;
