import React from "react";
import HeroSponsored2 from "../../assets/images/hero-sponsored-(2).svg";
import HeroCarousel from "./HeroCarousel";

const Hero = () => {
  return (
    <>
      <section className="hero section font-dm relative transition-all duration-300 dark:bg-gray-900 xl:pt-40">
        <div className="hero__container container grid gap-12 lg:grid-cols-2 lg:items-center xl:max-w-5xl">
          <div
            className="hero__data wow slideInRight grid gap-4 justify-self-center text-center xs:max-w-sm sm:max-w-lg lg:max-w-full lg:text-left"
            data-wow-duration="2s"
            data-wow-delay="5s"
          >
            <div>
              <h1 className="section-title xl:text-[50px] font-black capitalize">
                Building Innovative IT solutions
                <span className=" text-primary">.</span>
              </h1>
              <p className="hero__text section-text pb-0 xl:max-w-md">
                At Next Oasis we offer a range of solutions tailored
                specifically to meet the needs of your organisation be it SME or
                Multi-national. Our team of experienced professionals will help
                your business achieve digital advantage across every business
                area.
              </p>
            </div>

            <div className="hero__from flex flex-col gap-4 md:flex-row w-full xl:max-w-md">
              <a
                href="#contact"
                className="flex h-[52px] items-center justify-center rounded-lg bg-primary hover:bg-secondary  py-4 px-8 font-sans text-[15px] font-semibold capitalize text-white"
              >
                Get Started
              </a>
            </div>

            <div className="hero__sponsored inline-flex items-center mt-4 justify-center gap-3 lg:justify-start">
              <p className="hero__sponsored-text text-[12px] text-gray-600 dark:text-white">
                Trusted by:
              </p>

              <div className="hero__sponsored-list inline-flex items-center gap-2">
                {/* <img
                  src={HeroSponsored1}
                  alt="hero sponsored"
                  className="hero__sponsored-icon w-[4.5rem]"
                /> */}
                {/* <img
                  src={HeroSponsored3}
                  alt="hero sponsored"
                  className="hero__sponsored-icon w-12"
                /> */}
                <img
                  src={HeroSponsored2}
                  alt="hero sponsored"
                  className="hero__sponsored-icon w-14"
                />
              </div>
            </div>
          </div>

          <HeroCarousel />

          <div className="absolute bottom-0 left-0 z-[-1] hidden sm:block slideInRight"  data-wow-duration="2s"
            data-wow-delay="5s">
            <svg
              width="406"
              height="286"
              viewBox="0 0 406 286"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <rect
                  opacity="0.5"
                  x="56.25"
                  y="110.344"
                  width="116.719"
                  height="116.438"
                  stroke="url(#paint0_linear_116:1140)"
                />
                <rect
                  opacity="0.1"
                  x="56.25"
                  y="110.344"
                  width="116.719"
                  height="116.438"
                  fill="url(#paint1_linear_116:1140)"
                />
                <path
                  opacity="0.5"
                  d="M172.688 110.344L229.219 51V167.601L172.688 226.781V110.344Z"
                  stroke="url(#paint2_linear_116:1140)"
                />
                <path
                  opacity="0.1"
                  d="M172.688 110.344L229.219 51V167.601L172.688 226.781V110.344Z"
                  fill="url(#paint3_linear_116:1140)"
                />
                <path
                  opacity="0.5"
                  d="M0 169.619L56.25 110.344V226.85L0 286.125V169.619Z"
                  stroke="url(#paint4_linear_116:1140)"
                />
                <path
                  opacity="0.1"
                  d="M0 169.619L56.25 110.344V226.85L0 286.125V169.619Z"
                  fill="url(#paint5_linear_116:1140)"
                />
                <rect
                  opacity="0.5"
                  x="228.938"
                  y="51.2812"
                  width="119.25"
                  height="116.438"
                  stroke="url(#paint6_linear_116:1140)"
                />
                <rect
                  opacity="0.1"
                  x="228.938"
                  y="51.2812"
                  width="119.25"
                  height="116.438"
                  fill="url(#paint7_linear_116:1140)"
                />
                <path
                  opacity="0.5"
                  d="M347.906 51.2812L405 2V110.113L347.906 167.719V51.2812Z"
                  stroke="url(#paint8_linear_116:1140)"
                />
                <path
                  opacity="0.1"
                  d="M347.906 51.2812L405 2V110.113L347.906 167.719V51.2812Z"
                  fill="url(#paint9_linear_116:1140)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_116:1140"
                  x1="49.0781"
                  y1="112.313"
                  x2="148.922"
                  y2="131.859"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_116:1140"
                  x1="179.141"
                  y1="209.062"
                  x2="32.6026"
                  y2="145.47"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_116:1140"
                  x1="170.016"
                  y1="125.25"
                  x2="217.542"
                  y2="125.507"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_116:1140"
                  x1="233.578"
                  y1="113.156"
                  x2="146.509"
                  y2="143.95"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_116:1140"
                  x1="-3.45633"
                  y1="113.316"
                  x2="46.311"
                  y2="116.426"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_116:1140"
                  x1="69.8907"
                  y1="189.234"
                  x2="84.0124"
                  y2="249.947"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_116:1140"
                  x1="218.953"
                  y1="157.453"
                  x2="330.261"
                  y2="148.369"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_116:1140"
                  x1="348.187"
                  y1="46.6406"
                  x2="280.112"
                  y2="168.552"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_116:1140"
                  x1="329"
                  y1="190"
                  x2="369.525"
                  y2="-29.8829"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint9_linear_116:1140"
                  x1="409"
                  y1="10"
                  x2="331.729"
                  y2="34.2741"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
          <div className="absolute right-0 top-0 z-[-1] hidden sm:block wow slideInLeft"  data-wow-duration="2s"
            data-wow-delay="5s">
            <svg
              width="406"
              height="286"
              viewBox="0 0 406 286"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g opacity="0.5">
                <rect
                  opacity="0.5"
                  x="57.25"
                  y="108.344"
                  width="116.719"
                  height="116.438"
                  stroke="url(#paint0_linear_116:1151)"
                />
                <rect
                  opacity="0.1"
                  x="57.25"
                  y="108.344"
                  width="116.719"
                  height="116.438"
                  fill="url(#paint1_linear_116:1151)"
                />
                <path
                  opacity="0.5"
                  d="M173.688 108.344L230.219 49V165.601L173.688 224.781V108.344Z"
                  stroke="url(#paint2_linear_116:1151)"
                />
                <path
                  opacity="0.1"
                  d="M173.688 108.344L230.219 49V165.601L173.688 224.781V108.344Z"
                  fill="url(#paint3_linear_116:1151)"
                />
                <path
                  opacity="0.5"
                  d="M1 167.619L57.25 108.344V224.85L1 284.125V167.619Z"
                  stroke="url(#paint4_linear_116:1151)"
                />
                <path
                  opacity="0.1"
                  d="M1 167.619L57.25 108.344V224.85L1 284.125V167.619Z"
                  fill="url(#paint5_linear_116:1151)"
                />
                <rect
                  opacity="0.5"
                  x="229.938"
                  y="49.2812"
                  width="119.25"
                  height="116.438"
                  stroke="url(#paint6_linear_116:1151)"
                />
                <rect
                  opacity="0.1"
                  x="229.938"
                  y="49.2812"
                  width="119.25"
                  height="116.438"
                  fill="url(#paint7_linear_116:1151)"
                />
                <path
                  opacity="0.5"
                  d="M348.906 49.2812L406 0V108.113L348.906 165.719V49.2812Z"
                  stroke="url(#paint8_linear_116:1151)"
                />
                <path
                  opacity="0.1"
                  d="M348.906 49.2812L406 0V108.113L348.906 165.719V49.2812Z"
                  fill="url(#paint9_linear_116:1151)"
                />
              </g>
              <defs>
                <linearGradient
                  id="paint0_linear_116:1151"
                  x1="50.0781"
                  y1="110.313"
                  x2="149.922"
                  y2="129.859"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint1_linear_116:1151"
                  x1="180.141"
                  y1="207.062"
                  x2="33.6026"
                  y2="143.47"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint2_linear_116:1151"
                  x1="171.016"
                  y1="123.25"
                  x2="218.542"
                  y2="123.507"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint3_linear_116:1151"
                  x1="234.578"
                  y1="111.156"
                  x2="147.509"
                  y2="141.95"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint4_linear_116:1151"
                  x1="85.5"
                  y1="71.5"
                  x2="100.174"
                  y2="270.716"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint5_linear_116:1151"
                  x1="70.8907"
                  y1="187.234"
                  x2="85.0124"
                  y2="247.947"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint6_linear_116:1151"
                  x1="219.953"
                  y1="155.453"
                  x2="331.261"
                  y2="146.369"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint7_linear_116:1151"
                  x1="349.187"
                  y1="44.6406"
                  x2="281.112"
                  y2="166.552"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
                <linearGradient
                  id="paint8_linear_116:1151"
                  x1="394.75"
                  y1="64.3284"
                  x2="409.531"
                  y2="110.901"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" stopOpacity="0" />
                  <stop offset="1" stopColor="#293796" />
                </linearGradient>
                <linearGradient
                  id="paint9_linear_116:1151"
                  x1="410"
                  y1="8.00001"
                  x2="332.729"
                  y2="32.2741"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop stopColor="#293796" />
                  <stop offset="1" stopColor="#293796" stopOpacity="0" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </section>
    </>
  );
};

export default Hero;
