import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { About, Home, Service, Contact, Portfolio } from "./layouts/index"
import { Loading, Error } from "./components/index"
import WOW from "wowjs"

const App = () => {
  const [pageLoaded, setPageLoaded] = useState(false);

  useEffect(() => {
    // Simulate a brief delay using setTimeout
    setTimeout(() => {
      setPageLoaded(true);
    }, 1000); // Adjust the delay time as needed (1 second in this case)
  }, []);
  
  useEffect(() => {
    new WOW.WOW({
      live: false
    }).init();
  }, []);
  
  return (
    <>
    {!pageLoaded ? (
        <Loading />
      ) : (
      <Router>
        <Routes>
          <Route exact path="/" element={<Home />} />
          <Route path="*" element={<Error />} />
          <Route path="/about" element={<About />} />
          <Route path="/services" element={<Service />} />
          <Route path="/contact" element={<Contact />} />
          <Route path="/portfolio" element={<Portfolio />} />
        </Routes>
      </Router>
      )}
    </>
  );
};


export default App;
