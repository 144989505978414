import React from 'react'
import { Contact, Footer, Header, ScrollToTop } from '../components'
import Breadcrumb from '../components/Common/Breadcrumb';

const ContactUs = () => {
  window.scrollTo({
    top: "0",
    behavior: "smooth",
  });
  return (
    <>
      <ScrollToTop/>
      <div className="transition-all duration-300 dark:bg-gray-900 min-h-screen">
        <Header />
        <Breadcrumb pageName="Contact Us" description="Here you will find more information about Next Oasis Limited in detail." />
        <Contact />
        <Footer />
      </div>
    </>
  )
}

export default ContactUs
