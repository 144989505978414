import React from "react";
import AboutImg from "../../assets/images/hero/hero (6).jpg";

const More = () => {
  return (
    <section className="py-16 md:py-20 lg:py-28">
      <div className="container flex justify-center items-center w-full">
        <div className="-mx-4 flex flex-wrap items-center lg:max-w-6xl">
         
          <div className="w-full px-4 lg:w-1/2">
            <div className="wow fadeInUp lg:text-right" data-wow-delay=".2s">
              <div className="mb-6 text-center lg:text-end w-full flex justify-center flex-col">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  Our Mission
                </h3>
                <p className="text-base font-medium leading-relaxed text-body-color sm:leading-relaxed">
                  Our mission is to provide innovative and comprehensive digital
                  solutions through our expertise and helping businesses achieve
                  their goals in the competitive digital landscape.
                </p>
              </div>
              <div className="mb-6 text-center lg:text-end w-full flex justify-center flex-col">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  Our Vision
                </h3>
                <p className="text-base font-medium leading-relaxed text-body-color sm:leading-relaxed">
                  To help enterprises adopt technology in the most secured,
                  efficient and cost effective manner, by providing bespoke and
                  secured solutions that drive business innovation, agility and
                  customer satisfaction.
                </p>
              </div>
              <div className="mb-1 text-center lg:text-end w-full flex justify-center flex-col">
                <h3 className="mb-4 text-xl font-bold text-black dark:text-white sm:text-2xl lg:text-xl xl:text-2xl">
                  Our Mandate
                </h3>
                <p className="text-base font-medium leading-relaxed text-body-color sm:leading-relaxed">
                  Helping organizations focus on their core, while we power
                  their backend, making them more competitive.
                </p>
              </div>
            </div>
          </div>
          <div className="w-full px-4 lg:w-1/2">
            <div
              className="wow fadeInUp relative items-center flex justify-center mx-auto mt-12 aspect-[25/24]  text-center lg:m-0"
              data-wow-delay=".15s"
            >
              <div className="hero__img justify-self-center relative w-full sm:h-[480] h-[400px]  z-10 flex-col lg:h-[580px] p-2 border-2 border-primary rounded-3xl flex xs:max-w-sm sm:max-w-lg lg:max-w-full">
                <img
                  src={AboutImg}
                  alt="About"
                  className="hero__img justify-self-center w-full h-full object-cover object-center rounded-3xl"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default More;
