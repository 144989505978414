import SectionTitle from "../Common/SectionTitle";
import AboutImg from "../../assets/images/hero/hero (5).jpg";

const checkIcon = (
  <svg width="16" height="13" viewBox="0 0 16 13" className="fill-current">
    <path d="M5.8535 12.6631C5.65824 12.8584 5.34166 12.8584 5.1464 12.6631L0.678505 8.1952C0.483242 7.99994 0.483242 7.68336 0.678505 7.4881L2.32921 5.83739C2.52467 5.64193 2.84166 5.64216 3.03684 5.83791L5.14622 7.95354C5.34147 8.14936 5.65859 8.14952 5.85403 7.95388L13.3797 0.420561C13.575 0.22513 13.8917 0.225051 14.087 0.420383L15.7381 2.07143C15.9333 2.26669 15.9333 2.58327 15.7381 2.77854L5.8535 12.6631Z" />
  </svg>
);

const AboutSectionOne = () => {
  const List = ({ text }) => (
    <p className="mb-5 flex items-center w-full text-base font-medium text-body-color">
      <span className="mr-4 flex h-[30px] w-[30px] items-center justify-center rounded-md bg-primary bg-opacity-10 text-primary">
        {checkIcon}
      </span>
      {text}
    </p>
  );

  return (
    <section id="about" className="pt-20 md:pt-24 lg:pt-28">
      <div className="container r flex justify-center items-center w-full">
        <div className="border-b border-body-color/[.15] pb-16 dark:border-white/[.15] md:pb-20 lg:pb-28 lg:max-w-6xl">
          <div className="-mx-4 flex flex-wrap lg:flex-row flex-col-reverse items-center justify-center">
            <div className="w-full px-4 lg:w-1/2 flex items-center lg:justify-start justify-center">
              <div
                className="wow fadeInUp relative mx-auto aspect-[25/24] flex mb-6 lg:mb-0 justify-start items-center lg:mr-0"
                data-wow-delay=".2s"
              >
                <div className="hero__img justify-self-center relative w-full sm:h-[480] h-[400px]  z-10 flex-col lg:h-[580px] p-2 border-2 border-primary rounded-3xl flex xs:max-w-sm sm:max-w-lg lg:max-w-full">
                  <img src={AboutImg} alt="About" className="hero__img justify-self-center w-full h-full object-cover object-center rounded-3xl" />
                </div>
              </div>
            </div>
            <div className="w-full px-4 lg:w-1/2 flex flex-col text-center lg:text-start item-center lg:items-start flex-wrap justify-center">
              <SectionTitle
                title="About Us"
                paragraph="We are a full-service digital agency established to provide bespoke expert guidance and solutions for businesses to discover and utilise the true value of disruptive technology and transformation. At Next Oasis we offer a range of solutions tailored specifically to meet the needs of your organisation be it SME or Multi-national.  Our team of experienced professionals will help your business achieve digital advantage across every business area. Our Core Values are :"
                mb="44px"
              />

              <div
                className="wow fadeInUp mb-4 max-w-[570px] lg:mb-0"
                data-wow-delay=".15s"
              >
                <div className="mx-[-12px] flex flex-wrap w-full">
                  <div className="w-full px-3 sm:w-1/2 lg:w-full">
                    <List text="	Passion" />
                    <List text="	Effectiveness" />
                  </div>
                  <div className="w-full px-3 sm:w-1/2 lg:w-full">
                    <List text="	Integrity" />
                    <List text="	Customer Focus" />
                    {/* <List text="Graphics & UI/UX Designing" />
                    <List text="Project Management Consulting" /> */}
                  </div>

                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSectionOne;
