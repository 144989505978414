import React from "react";

const Loading = () => {
  return (
    <>
      <div className="w-full h-full fixed flex top-0 left-0 justify-center bg-slate-100 flex-col z-[1000] items-center transition-transform duration-500">
        <div className="w-full h-full justify-center flex items-center">
        <img src="/logo.png" className="w-64 animate-pulse" alt="logo" />
        </div>
      </div>
    </>
  );
};

export default Loading;
