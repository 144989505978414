import React, { useEffect } from "react";
import { AiFillCloseSquare } from "react-icons/ai";

const Modal = ({ isOpen, image, description, onClose }) => {
  useEffect(() => {
    const handleEscapeKey = (event) => {
      if (event.key === "Escape" && isOpen) {
        onClose();
      }
    };

    const handleClickOutside = (event) => {
      if (event.target === event.currentTarget && isOpen) {
        onClose();
      }
    };

    if (isOpen) {
      document.addEventListener("keydown", handleEscapeKey);
      document.addEventListener("click", handleClickOutside);
    }

    return () => {
      document.removeEventListener("keydown", handleEscapeKey);
      document.removeEventListener("click", handleClickOutside);
    };
  }, [isOpen, onClose]);

  return (
    <div
      className={`${
        isOpen
          ? "opacity-100 visible scale-100"
          : "opacity-0 invisible scale-90"
      } flex items-center justify-center transition-all duration-300 fixed top-0 left-0 w-full h-full z-[99]`}
    >
      <div
        className="fixed top-0 left-0 w-full h-full transition-all duration-300 bg-black bg-opacity-80 "
        onClick={onClose}
      ></div>
      <div className="bg-white p-px rounded-md shadow-lg w-full sm:max-w-xl max-w-sm transform scale-100">
        <button
          className="absolute top-0 right-0 text-3xl text-rose-600 hover:text-rose-800 bg-white"
          onClick={onClose}
        >
          <AiFillCloseSquare />
        </button>
        <img src={image} alt="Img" className="w-full rounded-md" />
        {/* <p className="mt-2">{description}</p> */}
      </div>
    </div>
  );
};

export default Modal;
